<template>
    <div>
        <v-btn icon @click="setTheme()">
            <v-icon>{{
                $vuetify.theme.isDark == true ? "icon-sun" : "icon-crescent"
            }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    mounted() {
        const theme = localStorage.getItem("dark_theme");
        if (theme) {
            if (theme == "true") {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        }
    },
    methods: {
        setTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem(
                "dark_theme",
                this.$vuetify.theme.dark.toString()
            );
        }
    }
};
</script>
