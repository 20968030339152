<template>
    <div>
        <!-- user profile  -->
        <v-menu :nudge-width="200" offset-y elevation="0">
            <template v-slot:activator="{ on, attrs }" class="pl-2">
                <div class="d-flex" v-bind="attrs" v-on="on">
                    <p class="mt-4 ml-3">{{ userInfo.userName }}</p>
                    <img src="../assets/img/user.svg" alt="John" width="30" />
                </div>
            </template>

            <v-card class="text-center" width="350" tile elevation="0">
                <v-container>
                    <v-img
                        class="mx-auto"
                        src="../assets/img/user.svg"
                        alt="zaid"
                        width="90"
                    >
                    </v-img>
                    <h3 class="text-center mt-3">
                        {{ userInfo.userName }}
                    </h3>
                    <v-card-text>
                        <div class="grey--text mt-n2">
                            {{ userInfo.email }}
                        </div>
                        <div>
                            <v-btn to="/userDetails" text block color="primary">
                                ادارة حسابك الشخصي
                            </v-btn>
                        </div>
                    </v-card-text>

                    <v-divider></v-divider>

                    <div class="pa-3">
                        <v-btn depressed outlined plain @click="logOut()">
                            تسجيل خروج
                        </v-btn>
                    </div>
                </v-container>
            </v-card>
        </v-menu>
        <!-- ./ user profile  -->
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            userInfo: {},
            dailogchangePassword: false
        };
    },
    created() {
        if (this.getUserDetails) this.getUserInfo();
    },

    computed: {
        ...mapGetters("auth", ["getUserDetails"])
    },
    methods: {
        getUserInfo() {
            this.$http
                .get("account/getUserById/" + this.getUserDetails.nameid)
                .then(response => {
                    this.userInfo = response.data.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        }
    }
};
</script>
<style lang=""></style>
