<template>
    <div>
        <appbar />
        <v-main>
            <v-container fluid>
                    <router-view :key="$route.path" />
            </v-container>
        </v-main>
    </div>
</template>

<script>
import Appbar from "./layout/Appbar.vue";

export default {
    name: "layout",
    components: {
        Appbar
    },
};
</script>
