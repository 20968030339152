import VueJwtDecode from "vue-jwt-decode";

export const auth = {
    namespaced: true,
    state: {
        user: JSON.parse(localStorage.getItem("user")),
        token: localStorage.getItem("token")
    },

    getters: {
        getUserDetails(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                // console.log("info user decoded token", decoded);
                return decoded;
            } catch (err) {
                console.log(err, "error from decoding token");
                return false;
            }
        }
    },

    mutations: {
        authUser(state, item) {
            state.user = item.user;
            state.token = item.token;
        },

        logout(state) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            state.token = null;
            state.user = null;
        }
    },

    actions: {
        login({ commit }) {
            commit("authUser", {
                user: JSON.parse(localStorage.getItem("user")),
                token: localStorage.getItem("token")
            });
        },

        logout({ commit }) {
            console.log("logout");
            commit("logout");
        }
    }
};
