<template>
    <div>
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant="mini"
            v-if="!$route.meta.hideNavigation"
            app
            width="300"
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
            bottom
            permanent
            :expand-on-hover="mini"
            floating
            right
        >
            <!-- img backround drawer  -->
            <!-- <v-img
                class="img-drawer"
                src="@/assets/bg-drawer.jpg"
                alt="bg-drawer"
            /> -->

            <v-list-item class="my-1 px-6 text-center">
                <img
                    width="40"
                    class="rounded-circle mr-n5"
                    src="@/assets/img/logo.jpg"
                />
                <span v-if="!mini" class="mr-2 mt-1">
                    معهد الكفيل لتقنية المعلومات
                </span>
            </v-list-item>

            <v-list nav>
                <template
                    v-for="(item, i) in items.filter(f => f.visible == true)"
                >
                    <v-list-group
                        exact
                        :key="i"
                        :prepend-icon="item.icon"
                        v-if="item.children"
                        v-model="item.active"
                    >
                        <template v-slot:activator>
                            <v-list-item-content class="mx-n3">
                                <v-list-item-title>
                                    {{ item.title }}</v-list-item-title
                                >
                            </v-list-item-content>
                        </template>

                        <v-list-item
                            active-class="primary"
                            color="white"
                            v-for="(subItem, ind) in item.children.filter(
                                f => f.visible == true
                            )"
                            :key="ind"
                            :to="subItem.to"
                        >
                            <v-list-item-icon> </v-list-item-icon>
                            <v-list-item-title class="mx-n2">
                                <v-icon>mdi-circle-small</v-icon>
                                {{ subItem.title }}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>

                    <v-list-item
                        v-else
                        :to="item.to"
                        :key="i"
                        active-class="primary"
                        color="white"
                    >
                        <v-list-item-icon>
                            <v-icon>
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="mx-n3">{{
                            item.title
                        }}</v-list-item-title>
                    </v-list-item>
                </template>
            </v-list>
            <!-- <v-divider class="grey" :class="mini ? 'mx-2' : ''"></v-divider> -->

            <!-- <itemDrawer /> -->
        </v-navigation-drawer>
    </div>
</template>

<script>
// import ItemDrawer from "./ItemDrawer.vue";
// import { mapGetters } from "vuex";

export default {
    name: "Drawer",
    components: {
        // ItemDrawer
    },

    data() {
        // ItemDrawer;
        return {
            hasRole: true,
            items: [
                {
                    icon: "mdi-view-dashboard-outline",
                    title: "الرئيسية",
                    to: "/",
                    visible: true
                },

                {
                    title: "إدارة الطوابق",
                    to: "/floors",
                    icon: "mdi-home-edit-outline",
                    group: "",
                    visible:
                        this.$store.getters["roles/floorsRole"] ||
                        this.$store.getters["roles/adminRole"]
                },

                {
                    title: "إدارة الغرف",
                    icon: "mdi-hospital-building",
                    group: "rooms",
                    visible:
                        this.$store.getters["roles/roomTypesRole"] ||
                        this.$store.getters["roles/adminRole"] ||
                        this.$store.getters["roles/roomsRole"],
                    children: [
                        {
                            active: false,
                            title: "إدارة انواع الغرف",
                            to: "/roomType",
                            visible:
                                this.$store.getters["roles/roomTypesRole"] ||
                                this.$store.getters["roles/adminRole"]
                        },
                        {
                            active: false,
                            title: "إدارة الغرف",
                            to: "/rooms",
                            visible:
                                this.$store.getters["roles/roomsRole"] ||
                                this.$store.getters["roles/adminRole"]
                        }
                    ]
                },

                {
                    title: "إدارة الموظفين",
                    to: "/staff",
                    icon: "mdi-account-cog-outline",
                    group: "",
                    visible:
                        this.$store.getters["roles/staffsRole"] ||
                        this.$store.getters["roles/adminRole"]
                },

                {
                    title: "إدارة الاشخاص المعرفين",
                    to: "/identifiers",
                    icon: "mdi-account-group-outline",
                    group: "",
                    visible:
                        this.$store.getters["roles/identifiersRole"] ||
                        this.$store.getters["roles/adminRole"]
                },

                {
                    title: "إدارة الحجوزات",
                    to: "/reservations",
                    icon: "mdi-briefcase-plus-outline",
                    group: "",
                    visible:
                        this.$store.getters["roles/reservationsRole"] ||
                        this.$store.getters["roles/adminRole"]
                },
                {
                    title: " إدارة عمليات الغرف",
                    to: "/operatons",
                    icon: "mdi-clipboard-check-multiple-outline",
                    group: "",
                    visible:
                        this.$store.getters["roles/operatonsRole"] ||
                        this.$store.getters["roles/adminRole"]
                },
                {
                    title: "إدارة انواع الوثائق",
                    to: "/documentTypes",
                    icon: "mdi-file-document-multiple-outline",
                    group: "",

                    visible:
                        this.$store.getters["roles/operatonsRole"] ||
                        this.$store.getters["roles/adminRole"]
                },

                {
                    title: "الصلاحيات و المستخدمين",
                    icon: "mdi-account-supervisor-outline",
                    group: "users",
                    visible: this.$store.getters["roles/adminRole"],
                    children: [
                        {
                            active: false,
                            title: "إدارة المستخدمين",
                            to: "/users",
                            visible: this.$store.getters["roles/adminRole"]
                        },
                        {
                            active: false,
                            title: " إدارة الصلاحيات",
                            to: "/groups",
                            visible: this.$store.getters["roles/adminRole"]
                        }
                    ]
                },
                {
                    title: "الإعدادات",
                    to: "/settings",
                    icon: "mdi-cog-outline",
                    group: "",
                    visible: this.$store.getters["roles/adminRole"]
                }
            ]
        };
    },

    created() {
        setTimeout(() => {
            var childs = this.items.filter(item => {
                return (
                    item.children &&
                    item.children.some(it => {
                        return it.to == this.$route.path;
                    })
                );
            });
            if (childs && childs.length > 0) {
                childs[0].active = true;
            }
        }, 200);
    },
    props: ["drawer", "mini"],

    methods: {}
};
</script>

<style lang="scss" scoped>
.img-drawer {
    position: absolute;
    opacity: 0.2;
    width: 100%;
    height: 100%;
}

.v-card-scroll {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}
</style>
