import VueJwtDecode from "vue-jwt-decode";

export const roles = {
    namespaced: true,
    state: {
        token: localStorage.getItem("token")
    },

    getters: {
        adminRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("0");
            } catch (err) {
                return false;
            }
        },
        floorsRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("1");
            } catch (err) {
                return false;
            }
        },
        floorAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("2");
            } catch (err) {
                return false;
            }
        },
        floorEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("3");
            } catch (err) {
                return false;
            }
        },
        floorDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("4");
            } catch (err) {
                return false;
            }
        },

        roomTypesRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("5");
            } catch (err) {
                return false;
            }
        },
        roomTypeAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("6");
            } catch (err) {
                return false;
            }
        },
        roomTypeEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("7");
            } catch (err) {
                return false;
            }
        },
        roomTypeDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("8");
            } catch (err) {
                return false;
            }
        },

        roomsRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("9");
            } catch (err) {
                return false;
            }
        },
        roomAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("10");
            } catch (err) {
                return false;
            }
        },
        roomEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("11");
            } catch (err) {
                return false;
            }
        },
        roomDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("12");
            } catch (err) {
                return false;
            }
        },

        staffsRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("13");
            } catch (err) {
                return false;
            }
        },
        staffAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("14");
            } catch (err) {
                return false;
            }
        },
        staffEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("15");
            } catch (err) {
                return false;
            }
        },
        staffDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("16");
            } catch (err) {
                return false;
            }
        },

        reservationsRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("17");
            } catch (err) {
                return false;
            }
        },
        reservationAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("18");
            } catch (err) {
                return false;
            }
        },
        reservationEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("19");
            } catch (err) {
                return false;
            }
        },
        reservationDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("20");
            } catch (err) {
                return false;
            }
        },

        identifiersRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("21");
            } catch (err) {
                return false;
            }
        },
        identifierAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("22");
            } catch (err) {
                return false;
            }
        },
        identifierEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("23");
            } catch (err) {
                return false;
            }
        },
        identifierDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("24");
            } catch (err) {
                return false;
            }
        },

        operatonsRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("25");
            } catch (err) {
                return false;
            }
        },
        operatonAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("26");
            } catch (err) {
                return false;
            }
        },
        operatonEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("27");
            } catch (err) {
                return false;
            }
        },
        operatonDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("28");
            } catch (err) {
                return false;
            }
        },

        documentTypesRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("29");
            } catch (err) {
                return false;
            }
        },
        documentTypeAddRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("30");
            } catch (err) {
                return false;
            }
        },
        documentTypeEditRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("31");
            } catch (err) {
                return false;
            }
        },
        documentTypeDeleteRole(state) {
            try {
                let decoded = VueJwtDecode.decode(state.token);
                return decoded.role.includes("32");
            } catch (err) {
                return false;
            }
        }
    },

    mutations: {
        authRoles(state, item) {
            state.token = item.token;
        }
    },

    actions: {
        setRoles({ commit }) {
            commit("authRoles", {
                token: localStorage.getItem("token")
            });
        }
    }
};
