import Vue from "vue";
import Vuetify from "vuetify/lib";
import "../style/style.scss";
import variabels from "@/style/variabels.scss";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    icons: {
        iconfont: "mdi"
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: variabels.primaryColor,
                secondary: variabels.secondaryColor,
                third: variabels.thirdColor,
                background: variabels.backgroundColor,
                accent: variabels.accentColor,
                error: variabels.errorColor,
                info: variabels.infoColor,
                success: variabels.successColor,
                warning: variabels.warningColor
            },

            dark: {
                primary: variabels.primaryColor,
                secondary: variabels.secondaryColor,
                third: variabels.thirdColor,
                background: "#262E35",
                accent: variabels.accentColor,
                error: variabels.errorColor,
                info: variabels.infoColor,
                success: variabels.successColor,
                warning: variabels.warningColor
            }
        }
    }
});
