import Vue from "vue";
import moment from "moment";

Vue.filter("formatDate", function(value) {
    if (value) {
        return value.substr(0, 10);
    }
});

Vue.filter("formatDateAgo", function(value) {
    if (value) {
        return moment(String(value)).fromNow(); // 4 years
    }
});

Vue.filter("uperCase", function(v) {
    return v.toUpperCase();
});

Vue.filter("phone", function(phone) {
    return phone
        .replace(/[^0-9]/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
});
