import axios from "axios";
import config from "../../public/static/config.json?v=1";

const url =
    process.env.NODE_ENV == "production"
        ? config.APP_URL_PROD
        : config.APP_URL_DEV;

export default axios.create({
    baseURL: url + "api/",
    headers: {
        Authorization: {
            toString() {
                return `Bearer ${localStorage.getItem("token")}`;
            }
        }
    }
});
