import Vue from "vue";
import Vuex from "vuex";
import config from "../../public/static/config.json?v=1";
import { auth } from "./auth.module";
import { roles } from "./roles.module";

Vue.use(Vuex);

const store = new Vuex.Store({
    // namespaced: true,
    state: {
        reservedRoomFromHome: {},
        reservedRoomsEdit: [],
        operationRoomIdFromHome: null,
        operationTypeFromHome: null,
        search: "",
        snackbars: [],
        mini_drawer: true,
        url:
            process.env.NODE_ENV == "production"
                ? config.APP_URL_PROD
                : config.APP_URL_DEV,

        // dialog add & edit
        dialog: false,
        dialogOpertionRoom: false,
        dialogOffOpertionRoom: false,
        bodyDetails: {},
        bodyDetailsOpertionRoom: {},
        dialogType: true,

        floorId: "",
        roomTypeId: "",
        identifierId: null,
        documentTypeId: "",
        reservationId: null,
        staffId: null
    },

    getters: {},

    mutations: {
        SET_SEARCH(state, search) {
            state.search = search;
        },

        SET_SNACKBAR(state, snackbar) {
            state.snackbars = state.snackbars.concat(snackbar);
        },

        SET_RESERVED_ROOMS(state, reservedRoom) {
            state.reservedRoomFromHome.roomId = reservedRoom.id;
            state.reservedRoomFromHome.roomNumber = reservedRoom.roomNumber;
        },

        SET_ROOMS(state, reservedRoom) {
            state.reservedRoomsEdit = reservedRoom;
        },

        SET_OPERATION_ROOMId(state, roomId) {
            state.operationRoomIdFromHome = roomId;
        },

        SET_OPERATION_TYPE(state, type) {
            state.operationTypeFromHome = type;
        },

        // mini_drawer
        TOGGLE_MINI_DRAWER(state) {
            state.mini_drawer = !state.mini_drawer;
        },
        SET_MINI_DRAWER(state, value) {
            state.mini_drawer = value;
        },

        // dialog add & edit
        SET_DIALOG(state) {
            state.dialog = !state.dialog;
        },
        SET_BODY_DETAILS(state, bodyDetails) {
            state.bodyDetails = bodyDetails;
        },

        SET_DIALOG_OPERATIONOOM(state) {
            state.dialogOpertionRoom = !state.dialogOpertionRoom;
        },
        SET_BODY_DETAILS_OPERATIONOOM(state, bodyDetailsOpertionRoom) {
            state.bodyDetailsOpertionRoom = bodyDetailsOpertionRoom;
        },

        SET_DIALOGOFFOPERTIONSROOM(state) {
            state.dialogOffOpertionRoom = !state.dialogOffOpertionRoom;
        }
    },

    actions: {
        setSnackbar({ commit }, snackbar) {
            snackbar.showing = true;
            snackbar.color = snackbar.color || "success";
            commit("SET_SNACKBAR", snackbar);
        },

        // search
        setSearch({ commit }, search) {
            commit("SET_SEARCH", search);
        },

        setReservedRooms({ commit }, reservedRoom) {
            commit("SET_RESERVED_ROOMS", reservedRoom);
        },

        setRooms({ commit }, reservedRoom) {
            commit("SET_ROOMS", reservedRoom);
        },

        setOperationRoomId({ commit }, roomId) {
            commit("SET_OPERATION_ROOMId", roomId);
        },
        setOperationType({ commit }, type) {
            commit("SET_OPERATION_TYPE", type);
        },

        // dialog add & edit
        setBodyDetails({ commit }, bodyDetails) {
            commit("SET_BODY_DETAILS", bodyDetails);
        },
        setDialog({ commit }) {
            commit("SET_DIALOG");
        },

        setBodyDetailsOpertionRoom({ commit }, bodyDetails) {
            commit("SET_BODY_DETAILS_OPERATIONOOM", bodyDetails);
        },
        setDialogOpertionRoom({ commit }) {
            commit("SET_DIALOG_OPERATIONOOM");
        },

        setDialogoPerstionsRoom({ commit }) {
            commit("SET_DIALOGOFFOPERTIONSROOM");
        },

        // mini_drawer
        toggleMiniDrawer({ commit }) {
            commit("TOGGLE_MINI_DRAWER");
        },
        setMiniDrawer({ commit }, value) {
            commit("SET_MINI_DRAWER", value);
        }
    },

    modules: {
        auth,
        roles
    }
});

export default store;
