import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import axios from "./axios/http-common";
import "sweetalert2/dist/sweetalert2.min.css";
import style from "./style/style.scss";
import "./utils/filters";
import VueCardFormat from "vue-credit-card-validation";
import validations from "@/utils/validations";
import GenericService from "@/services/GenericService";
import vueDebounce from "vue-debounce";

const pluginOptions = {
    /* see config reference */
    globalOptions: { currency: "USD" }
};

//401
axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            if (error.response.status == 401) {
                router.replace({
                    path: "/login",
                    query: { redirect: router.currentRoute.fullPath }
                });
                store.dispatch("auth/logout");
                // console.log("error 401");
            }
            if (error.response.status == 403) {
                router.push("/forbidden");
                // console.log("error 403");
            }
            return Promise.reject(error.response);
        }
    }
);

Vue.prototype.$http = axios;
Vue.prototype.$GenericService = GenericService;
Vue.prototype.$validations = validations;

Vue.use(VueSweetalert2);
Vue.use(VueCardFormat);
Vue.use(vueDebounce);

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

new Vue({
    style,
    axios,
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount("#app");
