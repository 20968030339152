import http from "../axios/http-common";
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const propSwalAlertConfirm = {
    title: "ةل تريد الاستمرار",
    text: "!! سوف يتم حذف  ةذة العنصر ولن يتمكن من أسترجاعة ",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    cancelButtonText: "لا",
    confirmButtonText: "نعم"
};

class GenericService {
    async getAll(controler) {
        try {
            return await http.get(controler);
        } catch (error) {
            return error;
        }
    }

    async get(controler) {
        return await http.get(controler);
    }

    async delete(id) {
        return await http.delete(id);
    }

    async create(controler, data) {
        return await http.post(controler, data);
    }

    async update(id, data) {
        return await http.put(id, data);
    }

    swalAlertConfirm(obj) {
        return Swal.fire({
            title: obj.title || propSwalAlertConfirm.title,
            text: obj.title || propSwalAlertConfirm.text,
            icon: propSwalAlertConfirm.icon,
            showCancelButton: propSwalAlertConfirm.showCancelButton,
            confirmButtonColor: propSwalAlertConfirm.confirmButtonColor,
            cancelButtonColor: propSwalAlertConfirm.cancelButtonColor,
            cancelButtonText: propSwalAlertConfirm.cancelButtonText,
            confirmButtonText: propSwalAlertConfirm.confirmButtonText
        });
    }

    swalAlertSuccess() {
        return Swal.fire({
            icon: "success",
            title: "اكتملت العملية بنجاح !",
            showConfirmButton: false,
            timer: 1000
        });
    }

    swalAlertError() {
        return Swal.fire({
            icon: "error",
            title: "حدث خطأ!!",
            confirmButtonText: "Cool"
        });
    }
}

export default new GenericService();
