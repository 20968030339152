<template>
    <v-app
        class="app"
        :style="{ background: $vuetify.theme.themes[theme].background }"
    >
        <layout />
        <alert-snackbar />
    </v-app>
</template>

<script>
import { mapState } from "vuex";
import AlertSnackbar from "./components/alertComponents/AlertSnackbar.vue";
import layout from "./components/layout";
export default {
    data() {
        return {};
    },
    components: {
        layout,
        AlertSnackbar
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        },
        ...mapState(["snackbars"])
    },
    created() {}
};
</script>

<style lang="scss">
@import "@/style/style.scss";

#app {
    font-family: "Cairo-Regular";
}
</style>
