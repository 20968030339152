<template>
    <div>
        <v-snackbar
            v-for="(snackbar, index) in snackbars.filter(s => s.showing)" 
            :key="snackbar.text + Math.random()"
            v-model="snackbar.showing"
            :timeout="snackbar.timeout"
            :multi-line="false"
            :style="`padding-bottom : ${(index * 50)}px; padding-right : ${(index * 10) + 5}px;`"
            :color="snackbar.color" left>
            {{ snackbar.text }}
            
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="withe" text
                    v-bind="attrs" 
                    @click="snackbar.showing = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>


        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState(["snackbars"])
    }
};
</script>