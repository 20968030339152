const text = "ةذا الحقل مطلوب";
let required = propertyType => {
    return v => (v && v.length > 0) || (propertyType ? propertyType : text);
};

let requiredRules = () => {
    return [v => !!v || "رجاءاً هذا الحقل مطلوب "];
};

let minLength = (propertyType, minLength) => {
    return v =>
        (v && v.length >= minLength) ||
        `${propertyType} يجب أن يكون على الأقل ${minLength} رمز`;
};
let maxLength = (propertyType, maxLength) => {
    return v =>
        (v && v.length <= maxLength) ||
        `${propertyType} يجب ان يكون على الأكثر ${maxLength} رمز`;
};

let emailFormat = () => {
    // let regex = /^'\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/;
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return v => (v && regex.test(v)) || "يجب إدخال بريد إلكتروني صالح";
};

let passwordFormat = () => {
    const regexPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/;
    return v =>
        (v && regexPassword.test(v)) ||
        "ستة أحرف كحد أدنى ، حرف كبير واحد على الأقل ، حرف صغير واحد ، رقم واحد وحرف خاص واحد";
};

let acceptNumber = value => {
    var x = this.value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    value = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
};

export default {
    acceptNumber,
    required,
    requiredRules,
    minLength,
    maxLength,
    emailFormat,
    passwordFormat
};
