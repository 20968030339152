import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/dashboard",
        name: "أحصائيات النظام",
        component: () => import("../components/Dashboard.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/",
        name: "الرئيسية",
        component: () => import("../views/Home.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/floors",
        name: "ادارة الطوابق",
        component: () => import("../views/Floor/floors.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/roomType",
        name: "ادارة انواع الغرف ",
        component: () => import("../views/RoomTypes/RoomTypes.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/rooms",
        name: "ادارة الغرف",
        component: () => import("../views/Room/Rooms.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/staff",
        name: "ادارة الموظفين ",
        component: () => import("../views/Staff/Staffes.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/identifiers",
        name: "ادارة الاشخاص المعرفين ",
        component: () => import("../views/Identifier/Identifiers.vue"),
        meta: {
            requireAuth: true
        }
    },

    {
        path: "/documentTypes",
        name: "ادارة انواع الوثائق ",
        component: () => import("../views/DocumentType/DocumentTypes.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/reservations",
        name: "ادارة الحجوزات",
        component: () => import("../views/Reservation/Reservations.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/reservation/edit/:id",
        name: "تعديل الحجز",
        props: true,
        component: () => import("../views/Reservation/DialogEdit.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/detailsReservation/:id",
        name: "تفاصيل الحجز",
        props: true,
        component: () => import("../views/Reservation/DetailsReservation.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/operatons",
        name: "ادارة عمليات الغرف ",
        component: () => import("../views/OperatonsRoom/Operatons.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/Login",
        name: "تسجيل الدخول",
        component: () => import("../views/Login.vue"),
        meta: {
            hideNavigation: true
        }
    },
    {
        path: "/users",
        name: "ادارة المستخدمين",
        component: () => import("../views/Users.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/groups",
        name: "ادارة الصلاحيات ",
        component: () => import("../views/Groups.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/settings",
        name: "الاعدادات",
        component: () => import("../components/Settings.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/userDetails",
        name: "ادارة الحساب ",
        component: () => import("../components/UserDetails.vue"),
        meta: {
            requireAuth: true
        }
    },
    {
        path: "/forbidden",
        name: "Forbidden",
        component: () => import("../components/Forbidden.vue"),
        meta: {
            hideNavigation: true
        }
    },
    {
        path: "/pageNotFound",
        name: "الصفحة غير موجودة",
        component: () => import("../components/404.vue"),
        meta: {
            hideNavigation: true
        }
    },
    {
        path: "*",
        redirect: "/pageNotFound"
    }
];

const router = new VueRouter({
    //mode: "history",
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const publicPages = ["/login", "/profile", "/forbidden"];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("token");
    window.document.title = to.name ? to.name : "Hotel";

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next("/login");
    } else {
        next();
    }
});

export default router;
