<template>
    <div>
        <v-app-bar
            app
            v-if="!$route.meta.hideNavigation"
            height="50"
            :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'"
            lights-out
            flat
        >
            <!-- app bar nav icon -->
            <v-app-bar-nav-icon
                @click="setMiniDrawer()"
                large
                :class="$vuetify.theme.isDark ? '' : 'blue-grey lighten-5'"
            >
                <v-icon>
                    {{ mini_drawer == true ? "mdi mdi-menu" : "mdi mdi-text" }}
                </v-icon>
            </v-app-bar-nav-icon>

            <!-- <v-btn
                @click="drawer = !drawer"
                icon=""
                depressed=""
                style="border-radius: 15px"
                fab
                class="mx-4">
                <v-icon large=""></v-icon>
            </v-btn> -->

            <v-breadcrumbs :items="itemsbreadcrumbs"></v-breadcrumbs>
            <!-- <v-breadcrumbs :items="itemsbreadcrumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item>
                        <v-btn
                            :to="item.href"
                            color="blue"
                            class="white--text"
                            text small
                        >
                            {{ item.text }}
                        </v-btn>
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs> -->

            <v-btn
                text
                outlined
                depressed
                :disabled="!adminRole && !reservationAddRole"
                v-if="$route.path != '/reservations'"
                @click="openReservations()"
                color="blue"
                class="white--text"
            >
                <v-icon class="ml-1 ">mdi-plus-circle</v-icon>
                حجز جديد
            </v-btn>

            <v-btn
                text
                outlined
                depressed
                :disabled="!adminRole && !operatonAddRole"
                v-if="$route.path != '/operatons'"
                @click="openDirectOperatons()"
                class="mr-2"
                color="red"
            >
                <v-icon class="ml-1">mdi-plus-circle</v-icon>
                تنظيف , صيانة
            </v-btn>

            <v-spacer></v-spacer>

            <!--  -->
            <v-btn icon to="/settings" v-if="adminRole">
                <v-icon>
                    mdi-cog-outline
                </v-icon>
            </v-btn>
            <theme-changer />

            <!-- btn fullscreen  -->
            <fullscreen class="ml-1" />

            <!-- <v-divider vertical inset class="ml-3" /> -->

            <!-- email -->
            <!-- <span class="ml-3">
                <v-badge color="red" overlap dot left>
                    <v-btn icon small>
                        <v-icon>
                            mdi-email-multiple-outline
                        </v-icon>
                    </v-btn>
                </v-badge>
            </span> -->

            <!-- <notifications /> -->

            <user-profile></user-profile>
        </v-app-bar>
        <drawer :drawer="drawer" :mini="mini_drawer" />
    </div>
</template>

<script>
import UserProfile from "../UserProfile.vue";
import Fullscreen from "../layout/Fullscreen.vue";
// import Notifications from "../layout/Notifications.vue";
import Drawer from "../layout/Drawer.vue";
import ThemeChanger from "../layout/ThemeChanger.vue";
import { mapGetters, mapState } from "vuex";

export default {
    name: "layout",
    components: {
        UserProfile,
        Fullscreen,
        // Notifications,
        Drawer,
        ThemeChanger
    },

    data() {
        return {
            itemsbreadcrumbs: [],
            drawer: true,
            loading: true
        };
    },

    computed: {
        ...mapState(["mini_drawer"]),
        ...mapGetters("roles", [
            "adminRole",
            "reservationAddRole",
            "operatonAddRole"
        ])
    },

    mounted() {
        const mini = localStorage.getItem("mini_drawer");
        if (mini) {
            if (mini == "true") {
                this.$store.dispatch("setMiniDrawer", true);
            } else {
                this.$store.dispatch("setMiniDrawer", false);
            }
        }
    },

    methods: {
        setMiniDrawer() {
            this.$store.dispatch("toggleMiniDrawer");
            localStorage.setItem("mini_drawer", this.mini_drawer.toString());
        },

        openReservations() {
            this.$router.push("/reservations");
            var item = {
                dialogType: "add"
            };
            this.$store.dispatch("setBodyDetails", item);
            this.$store.dispatch("setDialog");
        },

        openDirectOperatons() {
            this.$router.push("/operatons");
            var item = {
                dialogType: "add"
            };
            this.$store.dispatch("setBodyDetails", item);
            this.$store.dispatch("setDialog");
        }
    },

    watch: {
        $route(to, from) {
            if (to.path == "/") {
                this.itemsbreadcrumbs = [
                    {
                        text: "الرئيسية",
                        disabled: false,
                        href: "/"
                    }
                ];
            } else {
                this.itemsbreadcrumbs = [
                    {
                        text: "الرئيسية",
                        disabled: false,
                        href: "/"
                    },
                    {
                        text: to.name,
                        disabled: false,
                        href: to.path
                    }
                ];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.img-drawer {
    position: absolute;
    opacity: 0.3;
    width: 100%;
    height: 100%;
}

.v-card-scroll {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}
</style>
